@charset "utf-8";



// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #1b2152;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    100%;

$on-palm:          600px;
$on-laptop:        800px;


/*@media only screen
and (max-device-width: 667px) {
.post-meta-area {
width:100%;
clear:both;
}
.post {
width:100%;
clear:both;
}
}*/
// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
"base",
"layout",
"syntax-highlighting"
;

@media (min-width:700px) {

  .post-wrapper {
    border-top:1px solid $brand-color;
    padding-top:20px;
    vertical-align:top;

    .post-meta-area, .post-after {
      vertical-align:top;
      width:19%;
      display:inline-block;
      clear:none;
      padding:10px 10px;

      p {
        padding:0px 10px;
      }
    }
    .post-meta-area {
      text-align:right;
    }
    .post {
      vertical-align:top;
      width:58%;
      display:inline-block;
      clear:none;
    }
    .post-after {

    }
  }
}
@media (max-width: 699px) {
  .post-meta-area {
    display:none;
  }
  .post-after {
    padding-bottom:50px;
  }
}
.tagline {
  font-size:80%;
  color:$grey-color;
}

.tag {
  background-color:$brand-color;
  color:#ffffff;
  padding:3px 7px;
  font-size:60%;
  font-weight:bold;
}
